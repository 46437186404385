<template>
  <v-dialog
    v-model="dialogAtividadeCliente"
    @click:outside="$emit('update:dialogAtividadeCliente', false)"
    @keydown.esc="$emit('update:dialogAtividadeCliente', false)"
    scrollable
    fullscreen
  >
    <v-card tile :loading="loading">
      <!--TITULO -->
      <v-card-title class="pa-3 align-center accent text-h5 font-weight-bold">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon color="primary" left v-on="on">mdi-briefcase-edit-outline</v-icon>
            <span v-on="on">{{ atividade.titulo | excerpt(50) }}</span>
          </template>
          <span>{{ atividade.titulo }}</span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <v-chip
          :loading="loadingButton"
          dark
          label
          small
          depressed
          :color="
            atividade.status == 0
              ? 'grey'
              : atividade.status == 1
              ? 'blue-grey'
              : atividade.status == 2
              ? 'yellow darken-4'
              : atividade.status == 3
              ? 'green'
              : atividade.status == 4
              ? 'purple'
              : atividade.status == 5
              ? 'green darken-4'
              : atividade.status == 6
              ? 'deep-purple accent-4'
              : atividade.status == -1
              ? 'red'
              : 'light-green'
          "
        >
          {{ atividade.status | statusAtividade }}
        </v-chip>

        <v-divider vertical class="mx-5"></v-divider>
        <v-icon
          :color="
            atividade.prioridade === 1
              ? 'prioridade_baixa'
              : atividade.prioridade === 2
              ? 'prioridade_normal'
              : atividade.prioridade === 3
              ? 'prioridade_urgente'
              : 'prioridade_emergencia'
          "
        >
          mdi-flag
        </v-icon>
        <v-divider class="mx-5" vertical></v-divider>
        <v-sheet class="pa-2 text-body-1 accent" color="">
          {{ atividade.entrega | dateFormat("dd/MM/yyyy")
          }}<v-icon right>mdi-calendar</v-icon>
        </v-sheet>
        <v-btn icon @click="$emit('update:dialogAtividadeCliente', false)">
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <!-- conteudo -->
      <v-card-text
        style="height: calc(100vh - 69px); overflow: hidden"
        class="pa-0"
      >
        <v-row no-gutters>
          <!-- primeira coluna -->

          <v-col cols="12" md="8">
            <v-tabs v-model="tab">
              <v-tab>Informações</v-tab>
              <v-tab>Anexos</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-divider></v-divider>
                <v-card-text
                  class=""
                  style="height: calc(100vh - 179px); overflow: auto"
                >
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-sheet class="text-caption">Cliente</v-sheet>
                      <v-sheet
                        class="px-3 py-1"
                        rounded="rounded"
                        color="#F5F5F5"
                        >{{ atividade.cliente.nome_fantasia }}</v-sheet
                      >
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-sheet class="text-caption">Serviço</v-sheet>
                      <v-sheet
                        class="px-3 py-1"
                        rounded="rounded"
                        color="#F5F5F5"
                        >{{ atividade.servico.descricao }}</v-sheet
                      >
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-sheet class="text-caption"
                        >Descrição dos serviços</v-sheet
                      >
                      <v-textarea
                        class="py-1"
                        rounded="rounded"
                        color="#F5F5F5"
                        outlined
                        readonly
                        v-model="atividade.descricao_servicos"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-sheet class="text-caption"
                        >Informações pertinente</v-sheet
                      >
                      <v-textarea
                        class="py-1"
                        rounded="rounded"
                        color="#F5F5F5"
                        outlined
                        readonly
                        v-model="atividade.pertinentes"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-divider></v-divider>
                <v-col cols="12" md="12">
                  <InternFileManager
                    origem="atividades"
                    :origem_id="atividade.id"
                    title="Anexos"
                    :smallComponent="true"
                    :disabled="true"
                  />
                </v-col>
              </v-tab-item>
            </v-tabs-items>
            <v-divider></v-divider>
          </v-col>

          <!-- segunda coluna-->
          <v-col cols="12" md="4">
            <div class="pa-0" style="border-left: 1px solid #e0e0e0 !important">
              <v-btn-toggle
                class="py-1 mt-1"
                color="deep-purple accent-3"
                v-model="text"
                group
              >
                <v-btn value="all" small @click="selectedTipoComentario('all')">
                  <span class="hidden-sm-and-down">Todos</span>
                </v-btn>
                <v-btn
                  value="Histórico"
                  small
                  @click="selectedTipoComentario('Histórico')"
                >
                  <span class="hidden-sm-and-down">Histórico</span>
                </v-btn>
                <v-btn
                  value="Comentário"
                  small
                  @click="selectedTipoComentario('Comentário')"
                  ><span class="hidden-sm-and-down">Comentário</span>
                </v-btn>
              </v-btn-toggle>
            </div>
            <v-divider></v-divider>
            <v-card-text
              ref="container"
              style="
                border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
                height: calc(100vh - 265px);
                overflow: auto;
                background-color: #f5f5f5;
              "
            >
              <AtividadesComentarios
                v-if="atividade && atividade.id"
                :atividade_id="atividade.id"
                :comentario_add="comentarioAux"
                :comentario_tipo="comentarioTipo"
                :selected_tipo="selectedTipo"
                :key="comentarioKey"
                @last-scroll="scrollToEnd"
              />
            </v-card-text>
            <v-divider></v-divider>
            <v-card tile flat color="accent" class="px-3 py-2">
              <v-textarea
                name="comentario-cliente"
                label="Escreva aqui seu comentário"
                hint="comentário"
                no-resize
                rows="1"
                v-model="comentario"
                append-outer-icon="mdi-comment"
                :readonly="atividade.status == 7 ? true : false"
                @keydown.enter="addComentario()"
                @click:append-outer="addComentario()"
              >
              </v-textarea>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn
          class="mr-3"
          exact
          @click="$emit('update:dialogAtividadeCliente', false)"
        >
          voltar
        </v-btn>
        <v-divider vertical class="mx-2"></v-divider>
        <v-btn
          color="error"
          class="white--text mr-3 ml-2"
          :disabled="atividade.status !== 5"
          @click="statusChangeRefazer"
          :loading="loadingButton"
        >
          refazer
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="white--text mr-3 ml-2"
          :disabled="atividade.status !== 5"
          @click="statusChangeAprovar"
          :loading="loadingButton"
        >
          Aprovar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  putAtividadeCliente,
  fetchAtividade,
} from "@/api/geral/atividades_clientes.js";
import { mapState } from "vuex";

export default {
  name: "ViewAtividadeCliente",

  props: {
    dialogAtividadeCliente: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
    },
  },
  components: {
    InternFileManager: () =>
      import("@/components/midias/InternFileManager.vue"),
    AtividadesComentarios: () =>
      import("@/components/comentarios/AtividadesComentarios.vue"),
  },

  data() {
    return {
      atividade: {},
      loading: false,
      loadingButton: false,
      comentarioKey: 0,
      selectedTipo: "",
      comentarioAux: "",
      tab: 0,
      text: "all",
      comentario: null,
      comentarioTipo: 0,
      status: [
        { id: 6, stat: "REFAZER", cor: "deep-purple accent-4" },
        { id: 7, stat: "FINALIZADO", cor: "light-green" },
      ],
    };
  },

  computed: {
    ...mapState("Usuario", {
      usuarioName: (state) => state.usuario.name,
    }),
  },

  methods: {
    selectedTipoComentario(tipo) {
      this.comentarioTipo = null;
      this.comentarioAux = null;
      this.comentario = null;
      this.selectedTipo = tipo;
      this.comentarioKey += 1;
    },
    scrollToEnd() {
      let container = this.$refs.container;
      container.scrollTop = container.scrollHeight - container.clientHeight;
    },
    addComentario() {
      this.comentarioAux = this.comentario;
      this.comentarioTipo = 1;
      this.comentarioKey += 1;
      this.comentario = null;
    },
    statusChangeRefazer() {
      if (this.atividade.status == 5) {
        this.loadingButton = true;
        let atividade = {};
        atividade.status = 6;

        putAtividadeCliente(this.atividade.id, atividade).then(() => {
          this.comentarioAux = `${this.usuarioName}, mudou status para REFAZER`;
          this.comentarioTipo = 0;
          this.comentarioKey += 1;
          this.getAtividade();
          this.$emit("fetch-atividade");
          this.loadingButton = false;
        });
      }
    },
    statusChangeAprovar() {
      if (this.atividade.status == 5) {
        this.loadingButton = true;
        let atividade = {};
        atividade.status = 7;

        putAtividadeCliente(this.atividade.id, atividade).then(() => {
          this.comentarioAux = `${this.usuarioName}, mudou status para FINALIZADO`;
          this.comentarioTipo = 0;
          this.comentarioKey += 1;
          this.getAtividade();
          this.$emit("fetch-atividade");
          this.loadingButton = false;
        });
      }
    },

    getAtividade() {
      this.loading = true;
      return fetchAtividade(this.atividade.id)
        .then((response) => {
          this.atividade.status = response.status;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  created() {
    this.atividade = { ...this.item };
    this.$store.dispatch("Stopwatch/setAtividade", this.atividade);
  },
  mounted() {
    this.getAtividade();
  },
};
</script>

<style></style>
